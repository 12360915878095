import React from 'react';
import { Avatar, Badge, Popover } from "antd";
import { ClockCircleOutlined, DatabaseOutlined, LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

type Props = {
  loading?: boolean;
  date?: string;
}

const StatusIndicator: React.FC<Props> = ({loading, date}) => {
  const { t } = useTranslation('translation');

  return (
    <>
      <Popover placement="right" title={loading ? t("common.viewsRefreshing") : t("common.viewsUpToDate")} content={loading ? t("common.viewsRefreshingMore") : t("common.viewsLastUpdate") + date}>
        <Badge
          style={{position: "absolute"}}
          dot={true}
          status={loading ? 'error' : 'success'}
          count={ loading ? <ClockCircleOutlined style={{ color: '#f5222d' }} />  : ''}
        >
          <Avatar
            icon={loading ? <LoadingOutlined spin /> : <DatabaseOutlined />}
            shape="square"
          />
        </Badge>
      </Popover>
    </>
  )
}

export default StatusIndicator;