import React, { useState, useEffect } from 'react';

import Icon, { UserOutlined } from '@ant-design/icons';
//import { PermissionsContext } from 'context';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { MenuProps } from 'antd';

import { Sider } from 'components/UI';
import AllRoutes from 'screens/AllRoutes';
//import { PermissionsEnum } from 'services/graphql/main';

import { ConnectionBadge, StyledDownMenu, StyledIcon, StyledImg, StyledUpMenu } from './styles';
import pictoDashboardActif from 'assets/images/picto-dashboard-actif-fond.svg';
import pictoDashboardInactif from 'assets/images/picto-dashboard-inactif-fond.svg';
import pictoClientsActif from 'assets/images/picto-clients-actif-fond.svg';
import pictoClientsInactif from 'assets/images/picto-clients-inactif-fond.svg';
import pictoParametrageActif from 'assets/images/picto-parametrage-actif-fond.svg';
import pictoParametrageInactif from 'assets/images/picto-parametrage-inactif-fond.svg';
import logoSwitchSimple from 'assets/images/logo-Switch-simple.png';
import {getCacheVal, isValidToken, logout, useError} from 'services/utils';
import { ConfirmModal } from 'components/Modals';
import StatusIndicator from "../../../../components/UI/StatusIndicator/StatusIndicator";
import {useGetViewsStatusQuery} from "../../../../services/graphql/main";
import {dateFormatter} from "../../../../components/AgGridComponents/valueFormatters";
import moment from "moment/moment";

const MenuSider: React.FC = () => {
  const { t } = useTranslation('translation');
  const { addError } = useError();
  const location = useLocation();
  //const { permissions } = useContext(PermissionsContext);
  const navigate = useNavigate();

  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);

  const isLoggedIn = isValidToken(getCacheVal('token'));

  const [viewsRefreshing, setViewsRefreshing] = useState<boolean>(false);
  const [lastUpdate, setLastUpdate] = useState<string>("");

  const checkCurrentPathname = () => {
    const currentPathname = (
      location.pathname == AllRoutes.SwitchCreate || 
      location.pathname == AllRoutes.SwitchSimulate || 
      location.pathname == AllRoutes.SwitchHistory
      ) ? AllRoutes.Dashboard : location.pathname;
      return currentPathname;
  }

  const [currentSelected, setCurrentSelected] = useState(checkCurrentPathname);

  const itemsMenuList = [
    {
      key: AllRoutes.Dashboard,
      icon: <Icon component={()=>(<img src={pictoDashboardActif}/>)} />,
      activ: <Icon component={()=>(<img src={pictoDashboardActif}/>)} />,
      inactiv: <Icon component={()=>(<img src={pictoDashboardInactif}/>)} />
    },
    /*{
      key: AllRoutes.Customers,
      icon: <Icon component={()=>(<img src={pictoClientsInactif}/>)} />,
      activ: <Icon component={()=>(<img src={pictoClientsActif}/>)} />,
      inactiv: <Icon component={()=>(<img src={pictoClientsInactif}/>)} />,
    },*/
    {
      key: AllRoutes.Parameters,
      icon: <Icon component={()=>(<img src={pictoParametrageInactif}/>)} />,
      activ: <Icon component={()=>(<img src={pictoParametrageActif}/>)} />,
      inactiv: <Icon component={()=>(<img src={pictoParametrageInactif}/>)} />,
    },
  ];

  const [itemsMenu, setItemsMenu] = useState(itemsMenuList);

  const {
    loading: loadingViewsStatusQuery,
    data: viewsStatusDataQuery,
    refetch: refetchViewsStatusQuery,
  } = useGetViewsStatusQuery({ onError: (err) => addError(err, 'error') });

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetchViewsStatusQuery();
    }, 5000);
    return () => clearInterval(intervalId);
  }, [refetchViewsStatusQuery]);

  useEffect(() => {
    if (viewsStatusDataQuery) {
      setViewsRefreshing(viewsStatusDataQuery.viewsStatus.inProgress || viewsStatusDataQuery.viewsStatus.refreshDate == null);
      setLastUpdate(moment(viewsStatusDataQuery.viewsStatus.refreshDate).local().format('DD-MM-YYYY à HH:mm'));
    }
  }, [viewsStatusDataQuery]);

  useEffect(() => {
    if (location) {
      if (currentSelected !== location.pathname) {
        setCurrentSelected(checkCurrentPathname);
      }
    }
  }, [location, currentSelected]);

  const handleOnClickUpMenu: MenuProps['onClick'] = (event) => {
    let itemsTemp = itemsMenuList.map((item) => {
      if (event.key == item.key) {
        item.icon = item.activ;
      }
      else item.icon = item.inactiv;
      return item;
    });
    setItemsMenu(itemsTemp);
    setCurrentSelected(event.key);
    navigate(event.key);
  }

  const handleOnClickDownMenu: MenuProps['onClick'] = (event) => {
    setConfirmModalOpen(true);
  }

  return (
    <>
      <Sider>
        <StyledImg src={logoSwitchSimple} />
        <StyledUpMenu
          defaultOpenKeys={[AllRoutes.Dashboard]}
          items={itemsMenu}
          selectedKeys={[currentSelected]}
          onClick={handleOnClickUpMenu}
        />
        <StatusIndicator loading={viewsRefreshing} date={lastUpdate}/>
        <StyledDownMenu
          items={[{
                    key: '', 
                    icon: <StyledIcon>
                            <UserOutlined/>
                            <ConnectionBadge isConnected={isLoggedIn}/>
                          </StyledIcon>
                }]}
          onClick={handleOnClickDownMenu}
        />
      </Sider>
      <ConfirmModal
          customTitle={t('common.logout_confirmation')}
          onOk={logout}
          open={confirmModalOpen}
          showOnlyCloseBtn={false}
          onCancel={() => setConfirmModalOpen(false)}
        />
    </>
  );
};

export default MenuSider;
