import { Menu } from 'antd';
import styled from 'styled-components';

export const StyledImg = styled.img`
  width: 27px;
  height: 27px;
  margin: auto;
  display: block;
  margin-top: 19px;
  margin-bottom: 54px;
`;

export const StyledUpMenu = styled(Menu)`
  min-height: 70%;
  border-right: none;
  background-color: ${(p) => p.theme.white};
  color: ${(p) => p.theme.black};

  .ant-menu-item:hover,
  .ant-menu-item a:hover,
  .ant-menu-submenu:hover,
  .ant-menu-submenu-title:hover,
  .ant-menu-item-active,
  .ant-menu-submenu-active,
  .ant-menu-submenu-title-active,
  .ant-menu-item-open,
  .ant-menu-submenu-open,
  .ant-menu-item-selected,
  .ant-menu-item-selected a,
  .ant-menu-submenu-selected,
  .ant-menu-submenu-title-selected,
  .ant-menu-submenu > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ant-menu-title-content a {
    color: ${(p) => p.theme.white};
  }

  .ant-menu-item {
    padding: 0px;
    line-height: 0;
  }

  .ant-menu-item-selected a,
  .ant-menu-submenu-selected > .ant-menu-submenu-title {
    font-weight: bold;
  }

  .ant-menu-item-selected {
    background-color: ${(p) => p.theme.white} !important;
  }
`;

export const StyledDownMenu = styled(Menu)`
  position: absolute;
  bottom: 54px;
  border-right: none;

  .ant-menu-item {
    padding: 0px;
    line-height: 0;
  }
`;

export const StyledIcon = styled.div`
  background-color: ${(p) => p.theme.pale_gray};
  color: ${(p) => p.theme.black};
  width: 36px;
  height: 36px;
  border-radius: 50px;

  .anticon.anticon-user {
    min-width: 7px;
  }

  svg {
    position: absolute;
    bottom: 4px;
    width: 28px;
    height: 28px;
  }

`;

interface BadgeProps {
  isConnected: boolean;
}

export const ConnectionBadge = styled.div<BadgeProps>`
  background-color: ${(p) => p.isConnected ? p.theme.green : p.theme.red};
  width: 11px;
  height: 11px;
  border-radius: 6px;
  position: absolute;
  top: 0;
  right: 0;
`;
