import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';

import {
  TitleContainer,
  Title,
  GridContainer, FlexContainer, ItemContainer, StyledLabel, StyledPrimaryButton,

} from './styles';
import { ColDef } from 'ag-grid-community';
import {ConfirmModal, StyledModal} from 'components/Modals';
import { useError } from 'services/utils';
import {
  ProductSettingsDto,
  Season,
  StoreSettingDto,
  Universe,
  useGetSeasonsQuery, useGetSwitchesConfigsQuery,
  useGetStoresSettingLazyQuery,
  useGetStoresSettingQuery,
  useGetUniversesQuery,
  useUpdateStoreUniverseActiveMutation, useDeleteSwitchConfigMutation, SwitchConfig
} from 'services/graphql/main';
import {CaretDownOutlined, DeleteOutlined, FilterFilled, FormOutlined} from "@ant-design/icons";
import defaultTheme from 'theme';
import {currencyFormatter, dateFormatter} from 'components/AgGridComponents/valueFormatters';
import { Switch } from "antd";
import {toast} from "react-toastify";
import moment from "moment/moment";
import AllRoutes from "../../../../AllRoutes";
import {useNavigate} from "react-router-dom";

interface PropsStoreModal {
  customElement?: React.ReactNode;
  customTitle?: string;
  customTitleKey?: string;
  onCancel?: () => void;
  open: boolean;
  text?: string;
  textKey?: string;
  width?: number;
  height?: number;
}

const SwitchModal: React.FC<PropsStoreModal> = ({
  customElement,
  customTitle,
  customTitleKey,
  open,
  onCancel,
  width,
  height,
}) => {
  const { t } = useTranslation('translation');
  const { addError } = useError();
  const navigate = useNavigate();

  const [isGridReady, setIsGridReady] = useState(false);
  const gridRef = useRef<AgGridReact>(null);
  const [rows, setRows] = useState<[]>([]);

  const [switchConfigs, setSwitchConfigs] = useState<SwitchConfig[] | null>();

  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [switchIdToDelete, setSwitchIdToDelete] = useState<number | undefined>();

  const {
    loading: switchesConfigsQuery,
    data: switchesConfigsDataQuery,
    refetch: refetchSwitchesConfigsQuery,
  } = useGetSwitchesConfigsQuery({ variables: {isSaved: true}, onError: (err) => addError(err, 'error') });

  const [deleteSwitchConfigMutation] = useDeleteSwitchConfigMutation({
    onError: (err) => addError(err, 'error')
  });

  const colDefs: ColDef[] = useMemo( () => [
    {field: 'name', headerName: t('parameters.switch_name'), sortable: true, resizable: true, width: 250,  autoHeaderHeight: true, wrapHeaderText: true, filter: 'agTextColumnFilter', cellStyle: {textAlign: "center"}},
    {field: 'createAt', headerName: t('parameters.create_at'), sortable: true, resizable: true, width: 250,  autoHeaderHeight: true, wrapHeaderText: true, filter: 'agDateColumnFilter', valueFormatter: dateFormatter, cellStyle: {textAlign: "center"}},
    {field: 'address.addressRegion.name', headerName: t('parameters.store_region'), sortable: true, resizable: true, width: 200,  autoHeaderHeight: true, wrapHeaderText: true},
  ], [t]);

  const deleteSwitchConfig = useCallback(async (switchConfigId: number) => {
    const result = await deleteSwitchConfigMutation({
      variables: {
        switchConfigIdInput: {id: switchConfigId}
      },
      onError: (err) => addError(err, 'error')
    });

    if (result.data?.deleteSwitchConfig) {
      let msg = t('parameters.deleted')
      toast.success(msg, {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
  }, [deleteSwitchConfigMutation]);

  const onDelete = (id: number) => {
    deleteSwitchConfig(id).then(() => refetchSwitchesConfigsQuery());
    setConfirmModalOpen(false);
  };

  useEffect(() => {
    if (switchesConfigsDataQuery) {
      setSwitchConfigs(switchesConfigsDataQuery.switchesConfigs);
    }
  }, [switchesConfigsDataQuery]);
  
  return (
    <>
      <StyledModal
        bodyStyle={{height: 600}}
        width={!!width ? width : "900px"}
        centered
        closable={true}
        open={open}
        zIndex={2000}
        onCancel={onCancel}
        okButtonProps={{style: {display: 'none'}}}
        cancelButtonProps={{style: {display: 'none'}}}
      >
        <TitleContainer>
          <Title>{customTitleKey ? t(customTitleKey) : customTitle}</Title>
          {/*<ItemContainer style={{width: '95%'}}>*/}
          {/*  <FlexContainer style={{flexDirection: 'row'}}>*/}
          {/*    <ItemContainer style={{border: 'lightgray solid 1px', borderRadius: '10px', padding: 10, width: '33%'}}>*/}
          {/*      <FlexContainer style={{flexDirection: 'row'}}>*/}
          {/*        <FilterFilled />*/}
          {/*        <StyledLabel>NOM</StyledLabel>*/}
          {/*        <FilterFilled />*/}
          {/*      </FlexContainer>*/}
          {/*    </ItemContainer>*/}
          {/*    <ItemContainer style={{border: 'lightgray solid 1px', borderRadius: '10px', padding: 10, width: '33%'}}>*/}
          {/*      <StyledLabel>CREER LE</StyledLabel>*/}
          {/*    </ItemContainer>*/}
          {/*    <ItemContainer style={{width: '33%'}}>*/}
          {/*    </ItemContainer>*/}
          {/*  </FlexContainer>*/}
          {/*</ItemContainer>*/}
        </TitleContainer>

        <FlexContainer style={{flexDirection: 'column', paddingTop: 20, alignContent: "center", height: '90%'}}>
          {switchConfigs?.map((sw, index) => (
            <ItemContainer style={{border: 'lightgray solid 1px', borderRadius: '10px', margin: 5, padding: 10, width: '95%'}}>
              <FlexContainer style={{flexDirection: 'row'}}>
                <ItemContainer style={{width: '33%', display: "flex", justifyContent: "center"}}><StyledLabel>{sw.name}</StyledLabel></ItemContainer>
                <ItemContainer style={{width: '33%', display: "flex", justifyContent: "center"}}><StyledLabel>{moment(sw.createAt).local().format('DD-MM-YYYY')}</StyledLabel></ItemContainer>
                <ItemContainer style={{width: '33%'}}>
                  <FlexContainer style={{gap: '10px 5px', justifyContent: "flex-end"}}>
                    <StyledPrimaryButton icon={<FormOutlined />} onClick={() => navigate(AllRoutes.SwitchCreate+'/'+sw.id)} />
                    <StyledPrimaryButton icon={<DeleteOutlined />} onClick={() => {setConfirmModalOpen(true); setSwitchIdToDelete(sw.id); }} />
                  </FlexContainer>
                </ItemContainer>
              </FlexContainer>
            </ItemContainer>
          ))}
          {switchIdToDelete && <ConfirmModal
            customTitle={t('parameters.delete_confirmation')}
            onOk={() => onDelete(switchIdToDelete)}
            open={confirmModalOpen}
            showOnlyCloseBtn={false}
            onCancel={() => setConfirmModalOpen(false)}
          />}
        </FlexContainer>

        {/*<GridContainer gridWidth={width} gridHeight={height}>*/}
        {/*  <AgGridReact*/}
        {/*    rowStyle={{border: 'lightgray solid 1px', borderRadius: '18px', margin: '5px'}}*/}
        {/*    ref={gridRef}*/}
        {/*    columnDefs={colDefs}*/}
        {/*    rowData={switchesConfigsDataQuery?.switchesConfigs}*/}
        {/*    rowHeight={40}*/}
        {/*    rowSelection='multiple'*/}
        {/*    onGridReady={() => { setIsGridReady(true); }}*/}
        {/*  />*/}
        {/*</GridContainer>*/}

        {customElement && customElement}
      </StyledModal>
    </>
  );
};

export default SwitchModal;
